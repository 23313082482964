.dark {
  --primary-color: #1798de;
  --background-color: #000;
  --background-color-rgb: rgb(0,0,0);
  --text-color:#e5e5e5;
  --menu-background-color: rgba(0,0,0, 0.5);
  --dark-background-color: rgba(0,0,0, 0.8);
  --light-background-color: rgba(0,0,0, 0.5);
}

.light {
  --primary-color: #1798de;
  --background-color: #e5e5e5;
  --text-color: #000;
}
body {
  background: var(--background-color);
  color: var(--text-color);
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
html[lang="en-US"], body{
  font-family: raleway,sans-serif;
}
html[lang="fa-IR"], body{
  font-family: 'Dubai',sans-serif;
  font-size: 1.04em;
  line-height: 1.65em;
}
#root{
  width: var(--app-width);
  height: var(--app-height);
  display: flex;
  justify-content: center;
  align-items: center;
}
a{
  text-decoration: none;
  color: var(--primary-color);
  transition: all ease-in-out .6s;
}
a:hover{
  color: var(--primary-color);
  text-decoration: underline;
}
body::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@keyframes fade-in {
  from { 
    opacity: 0;
    
  }
  to { 
    opacity: 1;
    
  }
}
@keyframes fade-out {
  from { 
    opacity: 1;
    
  }
  to { 
    opacity: 0;
    
  }
}
@keyframes go-out-up {
  from { 
    opacity: 1;
    transform: translateY(0);
    
  }
  to { 
    opacity: 1;
    transform: translateY(-100%); 
    top: -100%;
    
  }
}
@keyframes come-in-down {
  from { 
    opacity: 1;
    transform: translateY(-100%); 
    top: -100%;
    
  }
  to { 
    opacity: 1;
    transform: translateY(0);
    
  }
}
@keyframes come-in-up {
  from { 
    opacity: 1;
    transform: translateY(100%); 
    bottom: -100%;
    
  }
  to { 
    opacity: 1;
    transform: translateY(0); 
    
  }
}
@keyframes go-out-down {
  from { 
    opacity: 1;
    transform: translateY(0); 
    
  }
  to { 
    opacity: 1;
    transform: translateY(100%); 
    bottom: -100%;
    
  }
}
@keyframes come-in-right {
  from { 
    opacity: 1;
    transform: translateX(100%); 
    right: -100%;
    
  }
  to { 
    opacity: 1;
    transform: translateX(0);
    
  }
}
@keyframes go-out-right {
  from { 
    opacity: 1;
    transform: translateX(0);
    
  }
  to { 
    opacity: 1;
    transform: translateX(100%); 
    right: -100%;
    
  }
}
@keyframes come-in-left {
  from { 
    opacity: 1;
    transform: translateX(-100%); 
    left: -100%;
    
  }
  to { 
    opacity: 1;
    transform: translateX(0);
    
  }
}
@keyframes go-out-left {
  from { 
    opacity: 1;
    transform: translateX(0);
    
  }
  to { 
    opacity: 1;
    transform: translateX(-100%); 
    left: -100%;
    
  }
}
@keyframes rotate{
  0%{
    transform:rotate(0deg)
  }
  100%{
    transform:rotate(360deg)
  }
}
.page-loading .video-js-container{
  visibility: hidden !important;
}
.page-loading .video-js-container .vjs-loading-spinner{
  visibility: hidden !important;
}
.page-active .video-js-container .vjs-loading-spinner{
  visibility: visible !important;
}
.page-active .video-js-container{
  visibility: visible !important;
}
.vjs-loading-spinner{
    border: 3px solid rgba(255, 255, 255, .15);
}
.vjs-custom-waiting:before{
  position: absolute;
  content: " ";
  left:0;
  top:0;
  width:var(--app-width);
  height: var(--app-height);
  background-color: rgba(0,0,0, 0.4);
  z-index: 1;
}
.vjs-custom-waiting .vjs-loading-spinner
{
    display: block;
    visibility: visible;
    z-index: 2;
}
.vjs-loading-spinner:before,
.vjs-loading-spinner:after
{
  margin: -3px !important;
  -webkit-animation: rotate 1s infinite linear !important;
  -moz-animation: rotate 1s infinite linear !important;
  -ms-animation: rotate 1s infinite linear !important;
  -o-animation: rotate 1s infinite linear !important;
  animation: rotate 1s infinite linear !important;
}
@supports (-webkit-touch-callout: none){
  body{
    height: -webkit-fill-available;
  }
}
.primary-text{
  color: var(--primary-color);
}
.video-ul-fa-wrapper{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 1em;;
}
.video-ul-fa{
  padding: .7em;
  width: 80%;
}
@media only screen and (max-width: 1200px) {
  .video-ul-fa{
    width: 100%;
  }
}
@media only screen and (min-width: 1350px) {
  .video-ul-fa{
    width: 70%;
  }
}
@media only screen and (min-width: 1700px) {
  .video-ul-fa{
    width: 60%;
  }
}
@media only screen and (min-width: 2000px) {
  .video-ul-fa{
    width: 50%;
  }
}